import Head from 'next/head';

function Page() {
  return (
    <>
      <Head>
        <title>404</title>
        <meta name="description" content="404 - nie znaleziono strony" />
      </Head>
      <h1 className="mx-auto text-center my-52 font-oswald">
        <b>404</b> - Nie znaleziono strony
      </h1>
    </>
  );
}

export default Page;
